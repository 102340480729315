@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";
@import "../../styles/animations.scss";

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-15px);
    }

    60% {
        transform: translateY(-7.5px);
    }
}

.home-container {
    @include container();
    color: $base-font-color;
    text-align: center;
}

.home-container .tv-icon {
    position: absolute;
    left: 10%;
}

.home-container .settings-icon {
    position: absolute;
    right: 10%;
}

.home-container .pushToTalkButton {
    display: flex;
    justify-content: center;
}

.home-container .show-tvcontroller-icon {
    position: absolute;
    bottom: 5%;
}

.home-container .date {
    font-size: 6rem;
    font-size: calc(14px + 2vw);
    font-weight: 500;
    text-transform: capitalize;
    font-family: "Montserrat";
}

.home-container .time {
    font-size: 14rem;
    font-size: calc(20px + 16vw);
    font-weight: bold;
    font-family: "Montserrat";
    text-shadow: 0.1em 0.1em 0.2em #000000b0;
}

.home-container .mute-icon {
    position: absolute;
    top: 5%;
}