.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: min-content;
}

.card {
    border: solid 4px #acacbb;
    min-width: 302px;
    background-color: black;
    height: 200px;
    border-radius: 20px;
    box-shadow: rgb(0 0 0 / 69%) 0px 26px 30px -10px, rgb(0 0 0 / 73%) 0px 16px 10px -10px;
    margin-right: 20px;
    margin-left: 20px;
    display: flex;
}

.card-container.selected .card {
    border: solid 4px #019df4 !important;
}

.card-container.selected .line {
    background-color: #019df4 !important;
}

.card-container .line {
    height: 4px;
    background-color: #acacbb;
    width: 30%;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    border-radius: 12px;
}