.Select {
    /* styling */
    background-color: transparent;
    border: 1px solid white;
    color: white;
    display: inline-block;
    border-radius: 5px;
    font-size: 1.5rem;
    line-height: 1.5em;
    padding: 0.2em 3.2em 0.2em 1em;
    margin: 0 0 0 20px;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
        outline: 0;
    }
}