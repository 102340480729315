.header {
    img {
        width: 2.5rem;
        height: auto;
        position: absolute;
        right: 4rem;
        top: 2rem;
        z-index: 1000;
        cursor: pointer;
    }
}