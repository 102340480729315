@import "../../styles/animations.scss";
@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";
@import "../../styles/select.scss";

.settings-main-container {
    @include container();
    color: $base-font-color;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: $bg-view-color;
    transform: translateX(100%);
    opacity: 0;
    animation: translateFromRight 0.5s forwards;

    &.hide {
        animation: translateToRight 0.5s forwards;
    }
}

.settings-main-container .settings-container {
    padding: 4rem 4rem 0 4rem;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.settings-main-container .settings-container h2 {
    padding: 0 0 .5rem 0;
    margin: 2.5rem 0 1.5rem 0;
    font-size: calc(14px + 1.5vw);
    border-bottom: 1px solid #fff;
    display: block;
    font-weight: 300;
    width: 90%;
    text-align: left;
}

.settings-main-container .settings-container .interval-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.settings-main-container .settings-container .stbs-list {
    margin-top: 20px;
    display: flex;
    overflow: scroll;
    padding-bottom: 100px;
}

.settings-main-container .title {
    font-size: 1.4vw;
    text-shadow: black 0.1em 0.1em 0.2em;
}

.card-container.selected .name {
    color: #019df4 !important;
}

.name {
    text-shadow: black 0.1em 0.1em 0.2em;
    font-size: 1.6rem;
}

.settings-main-container .settings-container .wallpaper-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 2vw;
    margin-top: 2vw;
}

.settings-main-container .settings-container .wallpaper-container .wallpapers-list {
    margin-top: 20px;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;

}

.wallpaper-card {
    flex-shrink: 0;
    width: 30vw;
    height: 19vw;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 2vw;
    border-radius: 20px;
    overflow: hidden;
    background-color: black;
}