@mixin container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

@mixin background-image($bg: '') {
    background-position: center;
    background-size: 100% auto;
    background-repeat: no-repeat;

    @if $bg !='' {
        background-image: url($bg);
    }
}