.tvcontroller-main-container {
    position: absolute;
    bottom: 13%;
    display: flex;
    justify-content: center;
    background: rgba(63, 63, 63, 0.4);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10.2px);
    -webkit-backdrop-filter: blur(10.2px);
    padding: 2vw;
    animation: translateFromBottom 0.5s forwards;

    &.hide {
        animation: translateToBottom 0.5s forwards;
    }
}

.tvcontroller-main-container .tvcontroller-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.tvcontroller-main-container .tvcontroller-container .row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-block-end: 0.5rem;
}

.tvcontroller-main-container .tvcontroller-container .row .btnicon {
    margin-right: 1vw;
    margin-left: 1vw;
}

.quiz-text {
    font-size: 18px;
}

@media(max-height: 600px) {
    .tvcontroller-main-container {
        bottom: 19%;
    }

    .tvcontroller-main-container .tvcontroller-container {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    .tvcontroller-main-container .tvcontroller-container .row {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-block-end: 0.5rem;
    }

    .tvcontroller-main-container .tvcontroller-container .row .btnicon {
        margin-top: 1vw;
        margin-bottom: 1vw;
    }


}