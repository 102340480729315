html {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    font-size: 10px;
    width: 100%;
    height: 100%;
    font-family: OnAir;
}

button {
    font-family: OnAir;
}

#root {
    height: 100%;
    width: 100%;
}

h1,
h2,
p {
    margin: 0;
    padding: 0;
}

* {
    user-select: none;
}