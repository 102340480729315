$movistar-color: #019df4;
$base-font-color: #fff;
$base-bg-color: #50535a;

$bg-color: #313131;
$bg-view-color: #0000004a;

$modal-dialog-bg-color: rgb(10, 10, 10);
$modal-dialog-font-color: #fff;

$alert-info-color: rgb(12, 84, 96);
$alert-info-border-color: rgb(12, 84, 96);
$alert-info-bg-color: rgba(209, 236, 241, .85);
$alert-info-separator-color: rgba(12, 84, 96, .3);
$alert-info-hover-color: #07bbd9;
$alert-info-hover-border-color: #07bbd9;

$alert-m-cloud-color: #fff;
$alert-m-cloud-border-color: #4a4a4a;
$alert-m-cloud-bg-color: rgba(10, 10, 10, 0.85);
$alert-m-cloud-separator-color: #4a4a4a;
$alert-m-cloud-hover-color: $movistar-color;
$alert-m-cloud-hover-border-color: $movistar-color;

$alert-danger-color: rgb(114, 28, 36);
$alert-danger-border-color: rgb(114, 28, 36);
$alert-danger-bg-color: rgba(248, 215, 218, .85);
$alert-danger-separator-color: rgba(114, 28, 36, .3);
$alert-danger-hover-color: #f2071b;
$alert-danger-hover-border-color: #f2071b;

$alert-warning-color: rgb(133, 100, 4);
$alert-warning-border-color: rgb(133, 100, 4);
$alert-warning-bg-color: rgba(255, 243, 205, .85);
$alert-warning-separator-color: rgba(133, 100, 4, .3);
$alert-warning-hover-color: #e6ae0b;
$alert-warning-hover-border-color: #e6ae0b;