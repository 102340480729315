.carrousel-card {
    margin-left: 30px;
    height: 75%;
    display: -webkit-flex;
    display: flex;
    border: 10px solid transparent;
    justify-content: center;

    .image-card {
        height: 100%;
        border-radius: 12px;
        border: 4px solid transparent;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        -ms-border-radius: 12px;
        -o-border-radius: 12px;
    }
}

.shadow {
    box-shadow: 0 26px 30px -10px rgba(0, 0, 0, .69), 0 16px 10px -10px rgba(0, 0, 0, .73);
    width: 91%;
    left: 4px;
    display: flex;
    justify-content: center;
}

.card-container {
    display: flex;
    flex-direction: column;
}

.container-iconhear {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-iconhear .icon-hear {
    color: white
}