@import "../../styles/animations.scss";
@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.recommender {
    @include container();
    animation: translateFromBottom 0.5s forwards;
    align-items: center;
    background-color: $bg-view-color;

    &.hide {
        animation: translateToBottom 0.5s forwards;
    }

    .carrousel-container {
        display: flex;
        height: 72%;
        overflow-x: scroll;
        width: 100%;
        align-items: center;
    }
}