@import '../../styles/colors.scss';

.btnicon {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: $base-font-color;
    background-color: #ffffff45;
    border-radius: 100%;
    padding: 15px;
}

.btnicon:hover {
    background-color: rgb(255 255 255 / 56%);
}

.btnicon .icon {
    font-size: calc(14px + 1vw);
}