@import '../../styles/mixins.scss';

.Wallpaper {
    z-index: -1;
    position: absolute;
    display: inline-block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #000;
    background-position: center;
    /* background-size: 100% auto; */
    background-repeat: no-repeat;
    object-fit: none;
    /* width: 100%; */
    /* height: 100%; */
    background-size: cover;
    /* @include background-image();*/
}

video {
    height: 100%;
    overflow: hidden;
    position: fixed;
}