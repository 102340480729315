.mute-container {
    text-align: center;
    font-size: calc(12px + 2vw);
    background-color: #9d2d9d;
    transform: translateY(-100%);
    opacity: 0;
    animation: muteFromTop 0.5s forwards;
    position: absolute;
    width: 100%;
    opacity: 0;
    color: white;

    &.hide {
        animation: muteToTop 0.5s forwards;
    }
}

.mute-container .message-container {
    padding: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mute-container .message-container .mute {
    font-size: calc(14px + 1.5vw);
    margin-right: 1vw;
}

.close {
    font-size: calc(14px + 1.5vw);
    right: 2vw;
    position: absolute;
}