@import "../../styles/animations.scss";
@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.tv-main-container {
    @include container();
    color: $base-font-color;
    text-align: center;
    font-size: calc(12px + 2vw);
    background-color: $bg-view-color;
    transform: translateX(-100%);
    opacity: 0;
    animation: translateFromLeft 0.5s forwards;

    &.hide {
        animation: translateToLeft 0.5s forwards;
    }
}

.tv-main-container .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.tv-main-container .content .image {
    width: 100%;
    z-index: -1;
}

.tv-main-container .content .info {
    width: 100%;
    position: absolute;
    bottom: 0;
    bottom: 3vh;
    z-index: 1;
}

.tv-main-container .content .info .logos-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tv-main-container .content .info .logos-container .logo-channel {
    min-width: 100px
}

.tv-main-container .content .info .logos-container .logo {
    min-width: 65px
}

.tv-main-container .content .info .body {
    text-shadow: black 0.1em 0.1em 0.2em;
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.graph {
    position: absolute;
    z-index: 10000;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.close-graph {
    width: 2.5rem;
    height: auto;
    position: absolute;
    right: 4rem;
    top: 2rem;
    z-index: 100000;
    cursor: pointer;
}

.graph-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}